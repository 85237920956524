<template>
  <v-container id="contact" fluid tag="section">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col
          cols="12"
          md="3"
          v-if="settings.BusinessFlowAvailable && settings.PrivateFlowAvailable"
        >
          <v-card rounded>
            <v-card-title class="text-uppercase pb-0">{{
              $t("Companytype")
            }}</v-card-title>
            <v-radio-group v-model="companyType" row class="mt-3">
              <v-col cols="12" md="6" v-if="settings.BusinessFlowAvailable">
                <v-radio :label="$t('Bussiness')" value="company"></v-radio>
              </v-col>
              <v-col cols="12" md="6" v-if="settings.PrivateFlowAvailable">
                <v-radio :label="$t('Private')" value="private"></v-radio>
              </v-col>
            </v-radio-group>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          :md="
            settings.BusinessFlowAvailable && settings.PrivateFlowAvailable
              ? 9
              : 12
          "
        >
          <v-card rounded :hidden="companyType !== 'company'">
            <v-card-title class="text-uppercase">{{
              $t("CoC Data")
            }}</v-card-title>
            <v-container class="py-0">
              <v-row>
                <v-col cols="9" md="5" class="py-0">
                  <v-text-field
                    v-model="kvkNumber"
                    :label="$t('CoCNumber')"
                    :append-icon="cocnotfound ? 'mdi-alert-circle' : ''"
                    :error-messages="cocerrortext"
                    :disabled="companyType !== 'company'"
                    maxlength="8"
                    :class="settings.KVKNumberRequired ? 'required' : ''"
                    :rules="settings.KVKNumberRequired ? requiredRules : []"
                  />
                </v-col>

                <v-col cols="9" md="3" class="py-0">
                  <v-text-field
                    v-model="establishmentNumber"
                    :label="$t('EstablishmentNr')"
                    :hint="$t('Optional')"
                    :disabled="companyType !== 'company'"
                    maxlength="12"
                  />
                </v-col>

                <v-col cols="9" md="4" v-if="settings.WebservicesEnabled">
                  <!-- :disabled="(!cocloading) && (companyType === 'private')" -->
                  <v-btn
                    :disabled="this.kvkNumber.length != 8"
                    :loading="cocloading"
                    color="primary"
                    @click="onCocLoad"
                    >{{ $t("FetchCoC") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="12">
          <v-card rounded :disabled="!companyType" :loading="cocloading">
            <v-card-title class="text-uppercase">{{
              $t("CustomerData")
            }}</v-card-title>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="8"
                  v-if="companyType !== 'private'"
                  class="py-0"
                >
                  <v-text-field
                    class="required"
                    v-model="companyTitle"
                    :label="$t('CompanyTitle')"
                    :rules="requiredRules"
                    append-icon="mdi-alert-circle"
                    v-if="companyType !== 'private'"
                    :disabled="filledCompany"
                    maxlength="50"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  v-if="
                    companyType !== 'private' &&
                    settings.OtherBankRequestBtwNrRequired
                  "
                  class="py-0"
                >
                  <v-text-field
                    class="required"
                    v-model="vatNumber"
                    :label="$t('vatNumber')"
                    :rules="requiredRules.concat(vatRules)"
                    append-icon="mdi-alert-circle"
                    maxlength="20"
                  />
                </v-col>                
              </v-row>
              <v-row>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="customerFirstname"
                    :label="$t('customerFirstname')"
                    :rules="requiredRules"
                    append-icon="mdi-alert-circle"
                    maxlength="20"
                  />
                </v-col>

                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="customerPrefix"
                    :label="$t('customerPrefix')"
                    maxlength="10"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="customerLastname"
                    :label="$t('customerLastname')"
                    append-icon="mdi-alert-circle"
                    :rules="requiredRules"
                    maxlength="30"
                  />
                </v-col>

                <v-col cols="12" md="12" class="py-0">
                  <v-autocomplete
                    v-model="country"
                    :items="countries"
                    item-text="name"
                    item-value="code"
                    :label="$t('country')"
                    :placeholder="$t('choosecountry')"
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="postcode"
                    :label="$t('postcode')"
                    :rules="requiredRules.concat(postcodeRules)"
                    append-icon="mdi-alert-circle"
                    :disabled="filledPostcode"
                    :placeholder="postcodePlaceholder"
                    maxlength="10"
                    @blur="blurPostCodeHouseNr"
                  />
                </v-col>

                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="housenr"
                    :label="$t('housenr')"
                    :rules="requiredRules"
                    :disabled="filledHousenr"
                    append-icon="mdi-alert-circle"
                    maxlength="10"
                    @blur="blurPostCodeHouseNr"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="city"
                    :label="$t('city')"
                    :rules="requiredRules"
                    append-icon="mdi-alert-circle"
                    :disabled="filledCity"
                    :loading="addressLoading"
                    maxlength="200"
                  />
                </v-col>

                <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="street"
                    :label="$t('street')"
                    :rules="requiredRules"
                    append-icon="mdi-alert-circle"
                    :disabled="filledStreet"
                    :loading="addressLoading"
                    maxlength="200"
                  />
                </v-col>

                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="tel1"
                    :label="$t('tel1')"
                    :rules="requiredRules.concat(phoneNumberRules)"
                    append-icon="mdi-alert-circle"
                    :placeholder="placeholderTextTel"
                    maxlength="16"
                  />
                </v-col>

                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="tel2"
                    :label="$t('mobilenumber')"
                    :rules="phoneNumberRules"
                    append-icon="mdi-alert-circle"
                    :placeholder="placeholderTextMobile"
                    maxlength="16"
                  />
                </v-col>
              
                <v-col
                  cols="12"
                  md="4"
                  v-if="(settings.ShowBirthDatePrivate) && (companyType === 'private')"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contactBirthDate"
                    :class="{ required: (settings.RequiredBirthDatePrivate && companyType === 'private') }"
                    :label="$t('Birthdate')"
                    :rules="
                      (settings.RequiredBirthDatePrivate && companyType === 'private') 
                        ? requiredRules.concat(dateRules)
                        : []
                    "
                    :append-icon="
                      (settings.RequiredBirthDatePrivate && companyType === 'private') ? 'mdi-alert-circle' : ''
                    "
                    placeholder="dd-mm-yyyy"
                    prepend-icon="mdi-calendar"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
                
                <v-col
                  cols="12"
                  md="12"
                  v-if="companyType === 'company'"
                  class="py-0"
                >
                  <v-text-field
                    v-model="website"
                    :label="$t('Website')"
                    :rules="websiteRules"
                    append-icon="mdi-alert-circle"
                    v-if="companyType === 'company'"
                    maxlength="200"
                  />
                </v-col> 
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="emailaddress"
                    :label="$t('emailaddress')"
                    append-icon="mdi-alert-circle"
                    :rules="requiredRules.concat(emailRules)"
                    maxlength="150"
                  />
                </v-col>               
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="companyType === 'company'">
        <v-col cols="12" md="12">
          <v-card :disabled="!companyType" rounded>
            <v-card-title class="text-uppercase">{{
              $t("ContactData")
            }}</v-card-title>
            <v-card-subtitle>
              <v-btn color="primary" @click="onCopyCustomerData">
                {{ $t("CopyCustomerdata") }}
              </v-btn>
            </v-card-subtitle>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="contactFirstname"
                    :label="$t('contactFirstname')"
                    :rules="requiredRules"
                    append-icon="mdi-alert-circle"
                    maxlength="20"
                  />
                </v-col>

                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="contactPrefix"
                    :label="$t('contactPrefix')"
                    maxlength="10"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="contactLastname"
                    :label="$t('contactLastname')"
                    :rules="requiredRules"
                    append-icon="mdi-alert-circle"
                    maxlength="30"
                  />
                </v-col>

                <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                    class="required"
                    v-model="contactEmailAddress"
                    :label="$t('emailaddress')"
                    :rules="requiredRules.concat(emailRules)"
                    append-icon="mdi-alert-circle"
                    maxlength="150"
                  />
                </v-col>

                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="contactMobilePhone"
                    :label="$t('tel1')"
                    :rules="phoneNumberRules"
                    append-icon="mdi-alert-circle"
                    :placeholder="placeholderTextMobile"
                    maxlength="16"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  :v-if="settings.ShowBirthDateBusiness && companyType === 'company'"
                  class="py-0"
                >
                  <v-text-field
                    v-model="contactBirthDate"
                    :class="{ required: (settings.RequiredBirthDateBusiness && companyType ==='company') }"
                    :label="$t('Birthdate')"
                    :rules="
                      (settings.RequiredBirthDateBusiness && companyType ==='company')
                        ? requiredRules.concat(dateRules)
                        : []
                    "
                    :append-icon="
                      (settings.RequiredBirthDateBusiness && companyType ==='company') ? 'mdi-alert-circle' : ''
                    "
                    placeholder="dd-mm-yyyy"
                    prepend-icon="mdi-calendar"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>        
        <v-col cols="6" class="text-left">
          <v-btn color="primary" tabindex="1" @click="onClearInput()">
            {{ $t("Clear") }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right float-right">
          <v-btn
            color="primary"
            class="mr-0"
            :loading="nextloading"
            @click="onNext"
          >
            {{ $t("Next") }} >
          </v-btn>
          <v-snackbar bottom multi-line timeout="10000" v-model="errsnackbar">
            {{ errsnackbar_text }}

            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="errsnackbar = false">
                {{ $t("Close") }}
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
/*eslint no-unused-vars: 0*/
/*eslint no-debugger: 0*/

import { mapFields } from "vuex-map-fields";
import { v4 as uuidv4 } from "uuid";
import { analyticService } from "../../services/analyticsservice";
import { checkVAT, countries as vatCountries } from "jsvat";

/*eslint-disable no-undef*/
const placeholderTextTel = config.PLACEHOLDERTEXT_TEL;
const placeholderTextMobile = config.PLACEHOLDERTEXT_MOBILE;

export default {
  computed: {
    ...mapFields([
      "companyTitle",
      "companyType",
      "kvkNumber",
      "establishmentNumber",
      "customerFirstname",
      "customerPrefix",
      "customerLastname",
      "country",
      "postcode",
      "housenr",
      "city",
      "street",
      "tel1",
      "tel2",
      "website",
      "emailaddress",
      "contactFirstname",
      "contactPrefix",
      "contactLastname",
      "contactEmailAddress",
      "contactMobilePhone",
      "contactBirthDate",
      "requestid",
      "defaultcard",
      "cards",
      "invoiceEmail",
      "invoiceByEmail",
      "awaitingMandate",
      "settings",
      "vatNumber",
      "filledCompany",
      "filledPostcode",
      "filledHousenr",
      "filledCity",
      "filledStreet",
    ]),
    postcodePlaceholder() {
      if (this.country == "31") {
        return "1234AB";
      } else if (this.country == "32") {
        return "1234";
      } else if (this.country == "49") {
        return "12345";
      }

      return "";
    },
  },
  data() {
    var countries = JSON.parse(this.$i18n.t("countrycodes"));
    return {
      countries,
      vatCountries: vatCountries,
      valid: true,
      requiredRules: [
        (v) => undefined !== v && v !== null && v && v.length !== 0,
      ],
      emailRules: [
        (v) =>
          /.+@.+\..+/.test(v) || v.length == 0 || this.$i18n.t("EmailInvalid"),
      ],
      phoneNumberRules: [
        (v) =>
          (v.replace(/[^\d]/g, "").length >= 10 && /^[0-9-+]+$/.test(v)) ||
          v.length == 0 ||
          this.$i18n.t("PhoneNumberInvalid"),
      ],
      websiteRules: [
        (v) =>
          /^(http[s]?:\/\/)?(www)?\.?.+\.[a-zA-Z]+$/.test(v) ||
          v.length == 0 ||
          this.$i18n.t("WebsiteInvalid"),
      ],
      vatRules: [
        (v) =>
          (v && v.length == 0) ||
          checkVAT(v, this.vatCountries).isValid ||
          this.$i18n.t("vatinvalid"),
      ],
      dateRules: [
        (v) =>
          (this.$moment(v, "DD-MM-YYYY", true).isValid() || this.$moment(v, "DD/MM/YYYY", true).isValid() || this.$moment(v, "DDMMYYYY", true).isValid()) ||
          v.length == 0 ||
          this.$i18n.t("DateInvalid"),
      ],
      postcodeRules: [
        (v) => {
          if (this.country == "31") {
            //NL
            return !v || v.length === 0 || /^\d{4}[a-zA-Z]{2}$/.test(v);
          } else if (this.country == "32") {
            //BG
            return !v || v.length === 0 || /^\d{4}$/.test(v);
          } else if (this.country == "49") {
            //DE
            return !v || v.length === 0 || /^\d{5}$/.test(v);
          }

          return true;
        },
      ],
      cocloading: false,
      errsnackbar: false,
      errsnackbar_text: "",
      addressLoading: false,
      nextloading: false,
      cocerrortext: "",
      cocnotfound: false,
      placeholderTextMobile: config.PLACEHOLDERTEXT_MOBILE,
      placeholderTextTel: config.PLACEHOLDERTEXT_TEL,
    };
  },
  methods: {
    onNext() {
      if (!this.$refs.form.validate()) {
        this.errsnackbar = true;
        this.errsnackbar_text = this.$t("validationallfields");
        return;
      }

      console.log("registering request...");
      this.nextloading = true;

      if (this.companyType !== "company") {
        this.onCopyCustomerData();
        this.companyTitle =
          (this.customerFirstname + " " + this.customerPrefix).trim() +
          " " +
          this.customerLastname;
      }

      this.$http({
        method: "post",
        url: "request",
        data: {
          PublicId: analyticService.getUserId(this),
          Id: this.requestid || 0,
          Name: this.companyTitle,
          DossierNumber: this.kvkNumber,
          CompanyAttention: this.customerPrefix
            ? this.customerFirstname +
              " " +
              this.customerPrefix +
              " " +
              this.customerLastname
            : this.customerFirstname + " " + this.customerLastname,
          Country: this.country,
          Postalcode: this.postcode,
          City: this.city,
          Street: this.street + " " + this.housenr,
          tel1: this.tel1,
          tel2: this.tel2,
          Website: this.website,
          InvoiceEmailAddress: this.emailaddress,
          ContactName: this.contactPrefix
            ? this.contactFirstname +
              " " +
              this.contactPrefix +
              " " +
              this.contactLastname
            : this.contactFirstname + " " + this.contactLastname,
          ContactEmail: this.contactEmailAddress,
          ContactTelephone: this.contactMobilePhone,
          ContactBirthDate: this.$moment(this.contactBirthDate, "DD-MM-YYYY", true).isValid()? this.contactBirthDate:
                            this.$moment(this.contactBirthDate, "DD/MM/YYYY", true).isValid()? this.$moment(this.contactBirthDate, "DD/MM/YYYY").format("DD-MM-YYYY"):
                            this.$moment(this.contactBirthDate, "DDMMYYYY", true).isValid()? this.$moment(this.contactBirthDate, "DDMMYYYY").format("DD-MM-YYYY"): "",
          CompanyType: this.companyType,
          VATNumber: this.vatNumber,
        },
      })
        .then((result) => {
          console.log("request registration completed.");
          console.log(result);

          var data = JSON.parse(result.data);
          console.log("request data", data);

          if (data.Id !== null) {
            this.requestid = data.Id;
          }

          this.$store.dispatch("persist");
          this.$router.push("cards");
        })
        .catch((error) => {
          console.log("request registration failed.");
          console.log("error");
          this.errsnackbar_text =
            this.$i18n.t("ErrorOccured") + " " + error.response.data
              ? error.response.data.ExceptionMessage
              : "";
          this.errsnackbar = true;
        })
        .then(() => {
          // code that always executes.
          this.nextloading = false;
        });
    },
    onCopyCustomerData() {
      if (this.customerFirstname) {
        this.contactFirstname = this.customerFirstname;
      }
      if (this.customerPrefix) {
        this.contactPrefix = this.customerPrefix;
      }
      if (this.customerLastname) {
        this.contactLastname = this.customerLastname;
      }
      if (this.emailaddress) {
        this.contactEmailAddress = this.emailaddress;
      }
      if (this.tel1) {
        this.contactMobilePhone = this.tel1;
      }
    },
    onClearInput() {
      this.$store.dispatch("purge");
    },
    blurPostCodeHouseNr() {
      if (!this.postcode || !this.city) {
        this.filledCity = false;
        this.filledStreet = false;
      }
      if (
        !this.city &&
        !this.street &&
        this.postcode &&
        this.housenr &&
        this.settings.WebservicesEnabled
      ) {
        console.log("Fetching street and city...");
        this.addressLoading = true;
        this.$http({
          method: "get",
          url: "address",
          params: {
            Postcode: this.postcode,
            Housenr: this.housenr,
          },
        })
          .then((result) => {
            console.log("Fetching address data succeeded.");
            var data = JSON.parse(result.data);
            if (data.City !== null) {
              this.city = data.City;
              this.filledCity = true;
            }
            if (data.Street !== null) {
              this.street = data.Street;
              this.filledStreet = true;
            }
            this.addressLoading = false;
          })
          .catch((error) => {
            console.log("Fetching address data failed.");
            console.log(error);
          })
          .then(() => {
            this.addressLoading = false;
          });
      }
    },
    onCocLoad() {
      console.log("Fetching coc data...");
      this.cocnotfound = false;
      this.cocerrortext = "";
      this.cocloading = true;
      this.$http({
        method: "get",
        url: "companyinfo",
        params: {
          KVKNumber: this.kvkNumber,
          EstablishmentNr: this.establishmentNumber,
        },
      })
        .then((result) => {
          console.log("Fetching coc data succeeded.");
          console.log(result);
          var data = JSON.parse(result.data);

          if (data.name !== null) {
            this.companyTitle = data.Name;
            this.filledCompany = true;
          }
          if (data.City !== null) {
            this.city = data.City;
            this.filledCity = true;
          }
          if (data.StreetBare !== null) {
            this.street = data.StreetBare;
            this.filledStreet = true;
          }
          if (data.HouseNr !== null) {
            this.housenr = data.HouseNr;
            this.filledHousenr = true;
          }
          if (data.Postcode !== null) {
            this.postcode = data.Postalcode;
            this.filledPostcode = true;
          }
          if (data.Website !== null) this.website = data.Website;
        })
        .catch((error) => {
          console.log("Fetching coc data failed.");

          this.cocnotfound = true;
          this.cocerrortext = this.$t("cocnotfound");
        })
        .then(() => {
          this.cocloading = false;
        });
    },
  },
  mounted() {
    if (!this.companyType || this.companyType === "") {
      if (
        this.settings.BusinessFlowAvailable &&
        !this.settings.PrivateFlowAvailable
      ) {
        this.companyType = "company";
      } else if (
        this.settings.PrivateFlowAvailable &&
        !this.settings.BusinessFlowAvailable
      ) {
        this.companyType = "private";
      }
    }

    if (!this.country || this.country === "") {
      this.country = this.settings.DefaultCountryCode;
    }

    // If there is no request id then the page was refreshed and the data is lost
    // Try to see if there is data in local storage
    if (!this.requestid) {
      this.$store.dispatch("rebuild");
      this.$forceUpdate();
    }

    if (this.awaitingMandate) {
      this.$router.replace("success");
    }
  },
};
</script>

